<template>
  <div>
    <header class="flex flex-col items-center bg-purple-900 py-2">
      <h1 class="text-slate-200  text-xs">
        Mobile Layout
      </h1>
    </header>
    <slot />
  </div>
</template>
